import { Component, OnInit, HostListener } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss']
})
export class CustomizerComponent implements OnInit {

  public screenwidth: any = window.innerWidth;
  public customizer: boolean = false;
  public layoutType: string = 'ltr';
  public sidebarType: string = 'compact-wrapper';
  public sidebarSetting: string = 'default-sidebar';
  public lightColorScheme: string = 'color-1';
  public darkColorScheme: string = 'default';
  public MIXLayout: string = 'default';

  constructor(private modalService: NgbModal,
    public layout: LayoutService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth;
  }
    
  // Open Modal
  openModal(popup) {
    this.modalService.open(popup, { backdropClass: 'dark-modal', centered: true });
  }

  // Open customizer
  Customizer() {
    this.customizer = !this.customizer;
  }

  // Customize Layout Type
  customizeLayoutType(val) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if(val == 'rtl') {
      document.getElementsByTagName('html')[0].setAttribute('dir', val);
    } else {
      document.getElementsByTagName('html')[0].removeAttribute('dir');
    }
  }

  // Customize Sidebar Type
  customizeSidebarType(val) {
    this.sidebarType = val;
    this.layout.config.settings.sidebar.type = val;
    this.layout.config.settings.sidebar.body_type = val == 'compact-wrapper' ? 'sidebar-icon' : 'horizontal-menu';
  }

  // Customize Sidebar Setting
  customizeSidebarSetting(val) {
    this.sidebarSetting = val;
    this.layout.config.settings.sidebar_setting = val;
  }

  // Customize Light Color
  customizeLightColorScheme(val) {
    this.lightColorScheme = val;
    this.layout.config.color.color = val;
    this.layout.config.color.mix_background_layout = 'light';
  }

  // Customize Dark Color
  customizeDarkColorScheme(val) {
    this.darkColorScheme = val;
    this.layout.config.color.color = val;
    this.layout.config.color.mix_background_layout = 'dark';
  }

  // Customize Mix Layout
  customizeMixLayout(val) {
    this.MIXLayout = val;
    this.layout.config.color.mix_background_layout = val;
  }

  ngOnInit() { }


}
