import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs/Subject';
@Injectable()
export class SearchService {
  private filterSource = new Subject<string>();
  filterSourceObservable$ = this.filterSource.asObservable();
  
  onDataChangeInfilter(data: any) {
    this.filterSource.next(data);
  }
  
}
