import { Routes } from '@angular/router';


export const content: Routes = [

  {
    path: 'search-pages',
    loadChildren: () => import('../../components/others/search-result/search-result.module').then(m => m.SearchResultModule)
  },

  {
    path: 'search',
    loadChildren: () => import('../../components/apps/job-search/job-search.module').then(m => m.JobSearchModule)
  },
 
];
