import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.servive';


export interface Credentials {
  token: string;
  username: string;
}


export interface LoginContext {
  username: string;
  password: string;
}

const credentialsKey = 'credentials';
const user = 'user';

@Injectable()
export class AuthenticationService {
  private _credentials: Credentials | null;
  private accessurl = 'auth/jwt/create/';
  private userurl = 'auth/me/';
  private createuserurl = 'auth/users/create/'

  constructor(private service: AppService, private router: Router ) {
    const savedCredentials = localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  login(context: LoginContext) {
    return this.service.post(this.accessurl, context).pipe(
      map(credentials => {
        localStorage.setItem('credentials', JSON.stringify(credentials));
        this.setCredentials(credentials);
        return of(true);
      })
    );
  }

  create(data){
    return this.service.post(this.createuserurl, data).pipe(
      map(res => {
        return res;
      })
    );


  }

  logout(): Observable<boolean> {
    this.setCredentials();
    this.router.navigate(['/auth/login']);
    return of(true);
  }

  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  get credentials(): Credentials | null {
    return this._credentials;
  }

  public username() {
    return this.service.get(this.userurl).pipe(
      map(res => {
        if (res) {          
          localStorage.setItem(user, JSON.stringify(res['result']));
          return true;
        }else{
          localStorage.removeItem(user);
          return false;
        }
      })
    );
  }

  public getusername() {
      let user = localStorage.getItem('user');
      if (!!user) {
          let details = JSON.parse(user);
          return details;
          
      }
  }

  private setCredentials(credentials?: Credentials) {
    this._credentials = credentials || null;
    if (credentials) {
      const storage = localStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));

    } else {
      localStorage.removeItem(credentialsKey);
      localStorage.removeItem(user);
      localStorage.removeItem('recents');

    }
  }
}
