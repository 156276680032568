<!-- page-wrapper Start-->

    <div  class="login-container">     
        <div class="login-center" *ngIf="!newUser">
            <form class="br"  [formGroup]="loginForm" >
                <img src="../../../assets/images/logo/cs1.png" class="center-img">
                <br>                
                <p class="text-center color-primary mb-0">Sign In </p>
                <br>                
                <div class="form-group">
                    <input class="form-control" placeholder="Username" type="text" required="" formControlName="username">
                    <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.required"
                        class="text text-danger mt-1">
                        username is required
                    </div>
                    <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.username"
                        class="text text-danger mt-1">
                        Invalid username
                    </div>
                </div>
                <div class="form-group">
                    <input class="form-control" placeholder="Password" type="password" required="" formControlName="password">
                    <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                        class="text text-danger mt-1">
                        Password is required
                    </div>
                </div>
                <div class="checkbox p-0">
                    <input id="checkbox1" class="checkbox_animated" type="checkbox">
                    <label for="checkbox1">Remember me</label>
                </div>
                <div class="form-group form-row mt-3 mb-0">
                    <button class="btn btn-primary btn-block"
                        (click)="login(loginForm.value)"
                        type="submit"><span>
                            Login
                            <!-- [class.loader--text]="authService.showLoader"
                            [disabled]="!loginForm.valid || authService.showLoader" -->
                            <!-- {{ authService.showLoader ? '' : 'Login' }} -->
                        </span>
                    </button>                
                </div>
                <div class="form-group form-row mt-3 mb-0">
                    <p class="text-center gray"> Don't have an account?
                    <a class="btn text-danger btn-sm p-0 sign-p" (click)="toggle()"> Sign Up </a>
                    </p>
                </div>
                <div class="social mt-5">
                </div>
            </form>
            <div class="br-p">
                <p class="text-center mt-2 gray">Copyright {{ today | date:'y'}} © Love For Data All rights reserved.</p>
            </div>
        </div>
        <div class="login-center" *ngIf="newUser">
            <form class="br" [formGroup]="registraionForm">
                <img src="../../../assets/images/logo/cs1.png" class="center-img">
                <br>                
                <p class="text-center color-primary mb-0">Sign Up</p>
                <br>     
                        <div class="form-group">
                            <input class="form-control" type="text"
                                placeholder="First Name" formControlName="first_name"
                                onlyAlphabets>
                        </div>
                    
                
                        <div class="form-group">
                            <input class="form-control" type="text"
                                placeholder="Last Name" formControlName="last_name"
                                onlyAlphabets>
                        </div>
                
                <div class="form-group">
                    <input class="form-control" type="text" placeholder="User Name"
                        formControlName="username">
                </div>
                <div class="form-group">
                    <input class="form-control" type="password" placeholder="Password"
                        formControlName="password">
                </div>
                <div class="form-group form-row mt-3 mb-0">
                    <button class="btn btn-primary btn-block" type="submit" 
                    (click)="signup_account(registraionForm.value)">Sign Up</button>
                </div>
                <div class="form-group form-row mt-3 mb-0">
                    <p class="text-center gray"> Have an account?
                    <a class="btn text-danger btn-sm p-0 sign-p" (click)="toggle()"> Sign In </a>
                    </p>
                </div>
                <div class="social mt-5">
                </div>
            </form>
            <div class="br-p">
                <p class="text-center mt-2 gray">Copyright {{ today | date:'y'}} © Love For Data All rights reserved.</p>
            </div>
        </div>   
    </div>


