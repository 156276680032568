<!-- Page Header Start-->
<div class="page-main-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="main-header-right row m-0 p-2">
    <form class="form-inline search-full" [class.open]="search">
      <div class="form-group w-100">
        <input class="form-control-plaintext" [(ngModel)]="text" [ngModelOptions]="{standalone: true}"
          (keyup)="searchTerm(text)" type="text" autocomplete="off" placeholder="Search..">
        <app-feather-icons [icon]="'x'" class="close-search" (click)="searchToggle()"></app-feather-icons>
        <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
          <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice:0:8">
            <div class="ProfileCard-avatar">
              <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
            </div>
            <div class="ProfileCard-details">
              <div class="ProfileCard-realName">
                <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{menuItem?.title}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
          <div class="tt-dataset tt-dataset-0">
            <div class="EmptyMessage">
              Opps!! There are no result found.
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="main-header-left" style="width: 35px !important;">
      <div class="logo-wrapper">
         <!-- <a routerLink='/'> -->
          <img class=" for-light" src="assets/images/logo/cs3.png" alt="" style="width: 150px;">
          <img class="for-dark" src="assets/images/logo/cs3.png" alt="" style="width: 150px;">
        <!-- </a>  -->
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="left-menu-header col horizontal-wrapper pl-0">
     <!-- <h5 class="pt-2 color-primary"> Compliance Scholar</h5> -->
    </div>
    <div class="nav-right col-8 pull-right right-menu">
      <ul class="nav-menus">
<!--        
        <li>
          <div class="mode" (click)="layoutToggle()">
            <i class="fa fa-moon-o" *ngIf="!dark"></i>
            <i class="fa fa-lightbulb-o" *ngIf="dark"></i>
          </div>
        </li>
         -->
        <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <div class="media profile-media">
            <div class="media-body"><span> {{login_details.first_name}}  {{login_details.last_name}} </span>
              <p class="mb-0 font-roboto"> {{login_details.username}} <i class="middle fa fa-angle-down"></i></p>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div " style="left:-75px">
            <!-- <li>
              <app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span>
            </li> -->
            <!-- <li>
              <app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span>
            </li> -->
            <li (click)="logout()" class="pt-0" style="border-top: 0px solid !important;">
              <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
            </li>
            <!-- <li >
            *ngIf="!authService.userData">

              <app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log in</span>
            </li> -->
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->