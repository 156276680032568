<!-- Page Sidebar Start-->
<div class="logo-wrapper">
  <a routerLink='/'>
    <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="">
    <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="">
  </a>
  <div class="back-btn" (click)="sidebarToggle()">
    <i class="fa fa-angle-left"></i>
  </div>
  <div class="toggle-sidebar" (click)="sidebarToggle()">
    <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
  </div>
</div>

