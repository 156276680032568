import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public signup: boolean;
  public user: any;
  public loginForm: FormGroup;
  public registraionForm: FormGroup;
  public today: number = Date.now();

  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };

  public errorMessage: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr : ToastrService,
    private authService: AuthenticationService
  ) {
    const url = this.route.snapshot.url;
    if (url[0].path === 'simple-login') {
      this.signup = false;
    }
    this.registraionForm = this.fb.group({
      // mobileNumber: [null, [Validators.required]]
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.loginForm = fb.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  // ngOnInit() {
  // }

  public toggle() {
    this.newUser = !this.newUser;
  }



  signup_account(obj){
    this.authService.create(obj).subscribe(res=>{
      this.toastr.success('Please login to continue.', 'Congratulations');
      this.signup = !this.signup;
    },err=>{
      this.toastr.error('Password is too short or similiar to the username', 'Hints');      
    })
  }


  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.route.queryParams.subscribe(params => this.router.navigate([params.redirect || '/'], { replaceUrl: true }));
    }

  }

  login(obj){
    this.authService.login(obj).subscribe(data => {
      this.route.queryParams.subscribe(params =>
        this.router.navigate([params.redirect || '/search/list'], { replaceUrl: true })
      );
    },
      err => {
        console.log(err);
        // this.invalid = true;
      });
  }

  

}
